export const FEATURES = [
  {
    headingOne: 'Effortless one-',
    headingTwo: 'minute setup.',
    text: 'Plug in and tap the screen to connect to Wi-Fi.',
  },
  {
    headingOne: 'Email photos to your',
    headingTwo: 'Skylight Frame in seconds.',
    text: `Everyone in the fam can send to your Skylight's unique email from their phone or computer, and photos arrive in seconds.`,
  },
  {
    headingOne: 'Easy-to-use touch',
    headingTwo: 'screen display.',
    text: 'Swipe through photos, browse the gallery, delete photos, or pause the slideshow - all with just a tap.',
  },
  {
    headingOne: 'We let you know when',
    headingTwo: 'you get new photos.',
    text: 'An alert pops up on the touchscreen whenever you get new photos - just tap to see them!',
  },
  {
    headingOne: 'The "Heart" button',
    headingTwo: 'lets you say thank you.',
    text: `Your loved one can tell you they loved a photo just by tapping the Heart button. Skylight then emails you to let you know!`,
  },
  {
    headingOne: 'Enjoy photos',
    headingTwo: 'without Wi-Fi.',
    text: `Skylight requires Wi-Fi to receive new photos, but you can still view your existing photos without a Wi-Fi connection.`,
  },
]
