export const PERFECT_GIFT_CONSTANTS = [
  {
    text: `There’s no better way to put a smile on your loved one’s face than to share a special photo — a glimpse of the kids being silly, a magical moment from your vacation adventure, or a sentimental memory from long ago.`,
  },
  {
    text: `For those of us who don’t love smartphones, Skylight can be a life-changing new way to connect with family. But even for the tech-savvy, there’s still something magical about seeing your most treasured photos in your home each day.`,
  },
]

export const PERFECT_GIFT_CALENDAR_PRODUCT_PAGE = [
  {
    text: `With the Plus Plan, your favorite memories will pop up on the device you check the most. That way, your Skylight Calendar always has a purpose, even when you’re not using it.`,
  },
  {
    text: `And here’s a tip: loved ones can send photos directly to your Cal, too! Be it via email or the app, loved ones can surprise you with new memories each and every day.`,
  },
]
