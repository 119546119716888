import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  FeatureContainer,
  Container,
  Row,
  Card,
  SecondaryHeader,
  Text,
  Header,
  TextContainer,
  ShapeContainerOne,
  ShapeContainerTwo,
  ShapeContainerThree,
  ShapeContainerFour,
  ShapeContainerFive,
  ShapeSixContainer,
  PortraitImageCard,
  LandscapeImageCard,
  PlusRowEnd,
} from './styles'

function Content(props) {
  const { headingOneLine, headingOne, headingTwo, text, width, i, textcolor } = props
  return (
    <Card key={i}>
      <TextContainer>
        {headingOneLine ? (
          <div>
            <SecondaryHeader color={textcolor} hideOnDesktop>
              {headingOneLine}
            </SecondaryHeader>
            <SecondaryHeader color={textcolor} hideOnMobile>
              {headingOne}
            </SecondaryHeader>
            <SecondaryHeader color={textcolor} hideOnMobile>
              {headingTwo}
            </SecondaryHeader>
            <Text width={width}>{text}</Text>
          </div>
        ) : (
          <div>
            <SecondaryHeader color={textcolor}>{headingOne}</SecondaryHeader>
            <SecondaryHeader color={textcolor}>{headingTwo}</SecondaryHeader>
            <Text color={textcolor} width={width}>
              {text}
            </Text>
          </div>
        )}
      </TextContainer>
    </Card>
  )
}
function Picture(props) {
  const { name, alt, portrait } = props
  if (portrait) {
    return (
      <PortraitImageCard>
        <GatsbyImage image={name.childImageSharp.gatsbyImageData} alt={alt} />
      </PortraitImageCard>
    )
  }
  return (
    <LandscapeImageCard>
      <GatsbyImage image={name.childImageSharp.gatsbyImageData} alt={alt} />
    </LandscapeImageCard>
  )
}

const FramePlusFeatures = ({ title, features, page }) => {
  const { t } = useTranslation('common')

  const {
    delight1,
    delight2,
    delight3,
    delight4,
    delight5,
    delight6,
    delightShape1,
    delightShape2,
    delightShape3,
    delightShape4,
    delightShape5,
    delightShape6,
  } = useStaticQuery(graphql`
    query FeaturesQuery {
      delight1: file(relativePath: { eq: "delight-1.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight2: file(relativePath: { eq: "delight-2.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight3: file(relativePath: { eq: "delight-3.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight4: file(relativePath: { eq: "delight-4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight5: file(relativePath: { eq: "delight-5.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight6: file(relativePath: { eq: "delight-6.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delightShape1: file(relativePath: { eq: "delight-shape-1.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delightShape2: file(relativePath: { eq: "delight-shape-2.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delightShape3: file(relativePath: { eq: "delight-shape-3.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delightShape4: file(relativePath: { eq: "delight-shape-4.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delightShape5: file(relativePath: { eq: "delight-shape-5.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: FIXED, placeholder: NONE)
        }
      }
      delightShape6: file(relativePath: { eq: "delight-shape-6.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delightShape4: file(relativePath: { eq: "delight-shape-4.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const defaultPictures = [delight1, delight2, delight3, delight4, delight5, delight6]

  const pictures = defaultPictures

  const contentWidth = ['250px', '315px', '300px', '325px', '335px', '290px']

  return (
    <FeatureContainer color="#dcf1f3">
      <Header color="#2178af">{title || t('Designed to Delight')}</Header>
      <Container>
        {features.map((content, i) => {
          const data = (
            <Content
              headingOneLine={t(content.headingOneLine)}
              headingOne={t(content.headingOne)}
              headingTwo={t(content.headingTwo)}
              text={t(content.text)}
              width={contentWidth[i]}
              i={i}
              textcolor="#2178af"
            />
          )
          if (i === 0) {
            return (
              <Row key={i}>
                <Picture name={pictures[0]} alt="Touch Screen Display Pinch Exmaple" portrait />
                {data}
                <ShapeContainerOne>
                  <GatsbyImage image={delightShape1.childImageSharp.gatsbyImageData} alt="Shape" />
                </ShapeContainerOne>
              </Row>
            )
          }
          if (i === 1) {
            return (
              <PlusRowEnd key={i} wrap="wrap-reverse">
                <ShapeContainerTwo>
                  <GatsbyImage image={delightShape2.childImageSharp.gatsbyImageData} alt="Shape" />
                </ShapeContainerTwo>
                {data}
                <Picture name={pictures[1]} alt="Baby In Crib" portrait={false} />
              </PlusRowEnd>
            )
          }
          if (i === 2) {
            return (
              <Row key={i}>
                <Picture name={pictures[2]} alt="Touch Screen Display Pinch Exmaple" portrait />
                {data}
                <ShapeContainerThree>
                  <GatsbyImage image={delightShape3.childImageSharp.gatsbyImageData} alt="Shape" />
                </ShapeContainerThree>
              </Row>
            )
          }
          if (i === 3) {
            return (
              <Row key={i} wrap="wrap-reverse">
                <ShapeContainerFour>
                  <GatsbyImage image={delightShape4.childImageSharp.gatsbyImageData} alt="Shape" />
                </ShapeContainerFour>
                {data}
                <Picture name={pictures[3]} alt="Young Women Potrait" portrait={false} />
              </Row>
            )
          }
          if (i === 4) {
            return (
              <Row key={i}>
                <Picture name={pictures[4]} alt="Child and Dog" portrait={false} />
                {data}
                <ShapeContainerFive>
                  <GatsbyImage image={delightShape5.childImageSharp.gatsbyImageData} alt="Shape" />
                </ShapeContainerFive>
              </Row>
            )
          }
          return (
            <Row key={i} wrap="wrap-reverse">
              <ShapeSixContainer>
                <GatsbyImage image={delightShape6.childImageSharp.gatsbyImageData} alt="Shape" />
              </ShapeSixContainer>
              {data}
              <Picture name={pictures[5]} alt="Grandma and Skylight Frame" portrait={false} />
            </Row>
          )
        })}
      </Container>
    </FeatureContainer>
  )
}

FramePlusFeatures.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array.isRequired,
  page: PropTypes.string,
}

Content.propTypes = {
  headingOneLine: PropTypes.string,
  headingOne: PropTypes.string,
  headingTwo: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.string,
  i: PropTypes.number,
  textcolor: PropTypes.string,
}
Picture.propTypes = {
  name: PropTypes.any,
  alt: PropTypes.string,
  portrait: PropTypes.bool,
}

export default FramePlusFeatures
