import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import { Section, Row, LogoContainer, Container, Header, Card, Text } from './styles'

import { PERFECT_GIFT_CONSTANTS, PERFECT_GIFT_CALENDAR_PRODUCT_PAGE } from './constants'

import { IS_FOREIGN_COUNTRY } from '../../utils/constants'

const PerfectGift = ({ page, title, bgColor, titleColor }) => {
  const { t } = useTranslation('common')

  const { perfectGift, internationalPerfectGift } = useStaticQuery(graphql`
    query PerfectGiftQuery {
      perfectGift: file(relativePath: { eq: "perfect-gift-loved-one-white.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      internationalPerfectGift: file(relativePath: { eq: "perfect-gift-loved-one.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const image = IS_FOREIGN_COUNTRY ? internationalPerfectGift : perfectGift

  const PERFECT_GIFT_CONSTANTS_OVERRIDE = {
    'calendar-skylight-plus': PERFECT_GIFT_CALENDAR_PRODUCT_PAGE,
  }
  const constants = PERFECT_GIFT_CONSTANTS_OVERRIDE[page] || PERFECT_GIFT_CONSTANTS
  return (
    <Section bgColor={bgColor}>
      <Row>
        <LogoContainer>
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="Perfect Gift" />
        </LogoContainer>
      </Row>
      <Header titleColor={titleColor} isCalendarPlus={false}>
        {title || t('The perfect gift for a loved one')}
      </Header>
      <Container>
        {constants.map((info, i) => (
          <Card key={info.text[0] + i}>
            <Text isCalendarPlus={false}>
              <Trans>{info.text}</Trans>
            </Text>
          </Card>
        ))}
      </Container>
    </Section>
  )
}

PerfectGift.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
}

export default PerfectGift
