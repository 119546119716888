import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  background-color: ${(props) => props.bgColor || props.theme.orange};
  padding: 80px 0px 90px;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 70px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 55px 0px;
  }
`
export const Header = styled.h2`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: 0.19px;
  text-align: center;
  margin: ${(props) => (props.isCalendarPlus ? 'auto' : '0px')};
  padding: 30px 20px;
  width: 100%;
  color: ${(props) => props.titleColor || props.theme.cream};
  @media (max-width: ${breakpoints.m}px) {
    font-size: 30px;
    padding: ${(props) => (props.isCalendarPlus ? '15px 0px' : '20px 20px')};
    width: ${(props) => (props.isCalendarPlus ? '80%' : '100%')};
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: ${(props) => (props.isCalendarPlus ? '25px' : '30px')};
    line-height: ${(props) => (props.isCalendarPlus ? '32px' : '1.39')};
  }
`
export const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.m}px) {
    justify-content: space-between;
  }
`
export const LogoContainer = styled.div`
  position: relative;
  width: 400px;
  margin: 0px 24px;
  @media (max-width: ${breakpoints.m}px) {
    width: 90%;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
`
export const Card = styled.div`
  width: 480px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 400px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 320px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 90%;
    margin: 10px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin: 10px auto;
  }
`
export const Text = styled.p`
  color: ${(props) => props.theme.grayDark};
  margin: 10px 0px;
  font-size: ${(props) => (props.isCalendarPlus ? '24px' : '18px')};
  line-height: ${(props) => (props.isCalendarPlus ? '30px' : '26px')};
  letter-spacing: ${(props) => (props.isCalendarPlus ? '0.08px' : '0.09px')};
  font-family: ${(props) => (props.isCalendarPlus ? 'FilsonProThin' : 'FilsonProBook')};
  @media (max-width: 1500px) {
    font-size: ${(props) => (props.isCalendarPlus ? '20px' : '18px')};
    line-height: ${(props) => (props.isCalendarPlus ? '28px' : '26px')};
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: left;
  }
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    font-size: ${(props) => (props.isCalendarPlus ? '20px' : '16px')};
    line-height: 24px;
    letter-spacing: 0.08px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: ${(props) => (props.isCalendarPlus ? '0px' : '0px 24px 0px')};
    font-size: ${(props) => (props.isCalendarPlus ? '18px' : '16px')};
  }
`
